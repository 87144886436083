import React, { useState } from "react";
import data from "../../Content/Products/OurProducts.json";
import ThumbnailSlider from "./ThumbnailSlider";
function OurProducts() {
  const [modalOpen, setModalOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const openModal = (index) => {
    setModalOpen(true);
    setSlideIndex(index);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className=" md:space-y-20 space-y-10 ">
      {data &&
        data?.list.map((item, index) => (
          <div key={index} className="md:flex flex-col md:flex-row  w-full ">
            {index % 2 === 0 ? (
              <div className="w-full flex  justify-center items-center md:p-10 p-5  md:gap-5 gap-3 text-black ">
                <div className="w-1/2  order-1 lg:px-5  space-y-3 ">
                  <h2 className="sm:text-2xl text-[10px]  w-full font-bold mb-5">
                    {item.title}
                  </h2>
                  <div className="space-y-1">
                    <h3 className=" text-blue-900 font-bold underline tracking-wide md:text-[16px] sm:text-[10px] text-[10px]  ">
                      {item.sub}:
                    </h3>
                    <p className="tracking-wide   md:text-[16px] sm:text-[10px] text-[7px] font-Nunito md:w-3/4 lg:leading-7">
                      {item.decsription}.
                    </p>
                  </div>

                  {item.content.map((d, i) => (
                    <div key={i} className="space-y-3">
                      <h2 className="md:text-[16px] text-[10px]  text-blue-900 underline tracking-wide font-bold  ">
                        {d.title1}
                      </h2>
                      <div className="space-y-0.5">
                        <p className="sm:text-[12px] text-[8px] font-Nunito tracking-wide ">
                          {" "}
                          {d.key1}
                        </p>
                        <p className="sm:text-[12px] text-[8px] font-Nunito tracking-wide ">
                          {d.key3}
                        </p>
                        <p className="sm:text-[12px] text-[8px] font-Nunito tracking-wide ">
                          {" "}
                          {d.key3}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="w-1/2  order-1 md:order-2">
                  <div className=" lg:p-5">
                    {/* <img
                      src={item.image}
                      alt={item.title}
                      className=" w-full md:rounded-tr-3xl  md:rounded-bl-3xl  rounded-tr-lg rounded-bl-lg lg:h-[500px] sm:h-[300px] h-[170px] "
                    /> */}
                    <ThumbnailSlider data={item.image} />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="bg-[#202D31] w-full md:p-10 p-5 flex md:gap-5 gap-3 justify-center items-center   ">
                  <div className="wrap-2 w-1/2 lg:p-5">
                    {/* <img
                      src={item.image}
                      alt={item.title}
                      className="md:rounded-tr-3xl w-full md:rounded-bl-3xl  rounded-tr-lg rounded-bl-lg  lg:h-[500px] sm:h-[300px] h-[170px]"
                    /> */}
                    <ThumbnailSlider data={item.image} />
                  </div>
                  <div className="w-1/2 lg:p-5  lg:py-8   space-y-2">
                    <h2 className="sm:text-2xl text-[10px] tracking-wide font-bold  text-white">
                      {item.title}
                    </h2>
                    <div className="space-y-1">
                      <h3 className=" text-white/80 underline font-bold tracking-wide md:text-[16px]  text-[10px]">
                        {item.sub}:
                      </h3>
                      <p className="tracking-wide  text-white/60 md:text-[16px] sm:text-[10px] text-[7px] font-Nunito md:w-3/4 lg:leading-7">
                        {item.decsription}.
                      </p>
                    </div>

                    {item.content.map((d, i) => (
                      <div key={i} className="space-y-2">
                        <h2 className=" md:text-[16px] text-[10px] underline tracking-wide font-bold  text-white/80">
                          {d.title1}
                        </h2>

                        <div className="space-y-0.5">
                          <p className="text-white/60 sm:text-[12px] text-[8px] font-Nunito tracking-wide ">
                            {d.key1}.
                          </p>
                          <p className="text-white/60 sm:text-[12px] text-[8px] font-Nunito tracking-wide ">
                            {d.key2}.
                          </p>
                          <p className="text-white/60 sm:text-[12px] text-[8px] font-Nunito tracking-wide ">
                            {d.key3}.
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
    </div>
  );
}

export default OurProducts;
