import React from "react";
import Data from "../../Content/Products/DryEye.json";
import ThumbnailSlider from "./ThumbnailSlider";

function DryEye() {
  return (
    <div className=" xl:px-0 px-3 max-w-7xl mx-auto  grid justify-center font-Nunito">
      <h2 className="text-center sm:text-3xl text-[20px] md:pb-10 py-3 tracking-wide">
        {Data.title}
      </h2>
      <div className="grid md:grid-cols-3 grid-cols-2 justify-center place-items-center gap-10">
        {Data?.list?.map((d, i) => (
          <div className="grid w-full h-full justify-center place-items-center">
            <ThumbnailSlider data={d.img} />
            <div>
              <p className="tracking-wide md:text-start text-center xl:text-[12px] sm:text-[10px] text-[6px]">
                {d.desc}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DryEye;
