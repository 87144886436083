import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import Navabar from "./Components/Common/Navabar";
import Footer from "./Components/Common/Footer";
import ProductsPage from "./Pages/ProductsPage";
import locomotiveScroll from "locomotive-scroll";

import AboutPages from "./Pages/AboutPages";
import WhatsappButton from "./Components/Common/WhatsappButton";
import GalleryPage from "./Pages/GalleryPage";
import ContactPage from "./Pages/ContactPage";
import ScrollToTop from "./Components/Common/ScrollToTop";
import CallButton from "./Components/Common/CallButton";
import React, { useEffect } from "react";
import Careers from "./Components/Careers/Careers";
import PatientInfoPage from "./Pages/PatientInfoPage";

function App() {
  // const scrollRef = React.createRef();

  // useEffect(() => {
  //   const scroll = new locomotiveScroll({
  //     el: scrollRef.current,
  //     smooth: true,
  //   });
  // });
  return (
    <div
    // className="scroll"
    // ref={scrollRef}
    // data-scroll
    >
      <BrowserRouter>
        <Navabar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/Suvan/Remedies/ProductsPage"
            element={<ProductsPage />}
          />
          <Route path="/Suvan/Remedies/ContactUs" element={<ContactPage />} />
          <Route path="/Suvan/Remedies/aboutPage" element={<AboutPages />} />
          <Route path="/Suvan/Remedies/gallery" element={<GalleryPage />} />
          <Route path="/Suvan/Remedies/Careers" element={<Careers />} />

          <Route
            path="/Suvan/Remedies/PatientInformation"
            element={<PatientInfoPage />}
          />
        </Routes>
        <Footer />
        <ScrollToTop />
        <CallButton />
        <WhatsappButton phoneNumber={+918977639771} />
      </BrowserRouter>
    </div>
  );
}

export default App;
