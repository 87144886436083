import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import data from "../../Content/Home/EyeDrop.json";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useInView } from "react-intersection-observer";
function EyeDropSlider() {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const [visible, setVisible] = React.useState(false);
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  React.useEffect(() => {
    if (inView) {
      setVisible(true);
    }
  }, [inView]);
  return (
    <div className="" ref={ref}>
      <div className="relative">
        <Slider
          ref={(slider) => {
            sliderRef = slider;
          }}
          {...settings}
        >
          {data.list.map((value) => (
            <div className="relative">
              <img
                src={value.Img}
                alt={value.Img}
                className="w-full md:h-[90vh] h-[40vh]  "
              />
              <div className="absolute lg:bottom-24 bottom-14  w-full ">
                <div className="sm:px-16 w-full  flex justify-center">
                  <h1 className="lg:text-xl p-1 sm:text-[12px] text-[8px] text-center text-black py-2 font-Nunito tracking-wide bg-gray-50/80 w-fit ">
                    {value.desc}
                  </h1>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className=" w-fit absolute md:top-[50%] top-[40%] md:left-5 left-1 md:text-4xl text-2xl px-4 ">
          <button
            className="  bg-transparent border  shadow-sm rounded-full"
            onClick={previous}
          >
            <IoIosArrowBack className="md:text-6xl text-3xl text-white" />
          </button>
        </div>
        <div className="w-fit  absolute md:right-5 right-1 md:top-[50%] top-[40%] md:text-4xl text-2xl px-4">
          <button
            className="  bg-transparent border shadow-sm rounded-full "
            onClick={next}
          >
            <IoIosArrowForward className="md:text-6xl text-3xl text-white" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default EyeDropSlider;

// import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

// import { Swiper, SwiperSlide } from "swiper/react";
// import data from "../../Content/Home/EyeDrop.json";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/scrollbar";

// function EyeDropSlider() {
//   return (
//     <Swiper
//       // install Swiper modules
//       modules={[Navigation, Pagination, Scrollbar, A11y]}
//       spaceBetween={50}
//       slidesPerView={}
//       navigation
//       pagination={{ clickable: true }}
//       scrollbar={{ draggable: true }}
//       onSwiper={(swiper) => console.log(swiper)}
//       onSlideChange={() => console.log("slide change")}
//     >
//       <div className="pb-10">
//         <div className="relative">
//           {data.list.map((value) => (
//             <SwiperSlide>
//               <div className="relative">
//                 <img
//                   src={value.Img}
//                   alt={value.Img}
//                   className="w-full md:h-[90vh] h-[35vh] "
//                 />

//                 <div className="absolute top-[53%]  w-full ">
//                   <div className="px-16 w-full ">
//                     <h1 className="text-3xl text-center ">{value.desc}</h1>
//                   </div>
//                 </div>
//               </div>
//             </SwiperSlide>
//           ))}
//         </div>
//       </div>
//       ...
//     </Swiper>
//   );
// }

// export default EyeDropSlider;
