import React from "react";
import data from "../../Content/Home/Expert.json";

import { useInView } from "react-intersection-observer";
import { Fade } from "react-reveal";
const Experts = () => {
  const [visible, setVisible] = React.useState(false);
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  React.useEffect(() => {
    if (inView) {
      setVisible(true);
    }
  }, [inView]);
  return (
    <div className="bg-[#FFCF40]" ref={ref}>
      <div className="py-3 md:p-2   p-1 md:(px-5) md:px-3 max-w-7xl mx-auto   text-gray-900">
        <div className="md:flex gap-10 md:space-y-0 space-y-8 md:py-10 py-5">
          <div className="grid md:space-y-5 space-y-1 md:text-lg md:w-1/2">
            <h1 className="md:text-2xl  text-xl font-semibold uppercase">
              {data.title}
            </h1>
            <Fade bottom delay={50} when={visible}>
              <p>{data.p1}</p>
            </Fade>
            <Fade bottom delay={75} when={visible}>
              <p>{data.p2}</p>
            </Fade>
            <Fade bottom delay={100} when={visible}>
              <p>{data.p3}</p>
            </Fade>
            <Fade bottom delay={125} when={visible}>
              <p>{data.p4}</p>
            </Fade>
            <Fade bottom delay={150} when={visible}>
              <p>{data.p5}</p>
            </Fade>
          </div>
          <div className="space-y-5 md:text-lg md:w-1/2">
            <h1 className="md:text-2xl text-xl font-semibold uppercase">
              {data.heading}
            </h1>
            <Fade bottom delay={50} when={visible}>
              <p>{data.p6}</p>
            </Fade>
            <Fade bottom delay={75} when={visible}>
              <p>{data.p7}</p>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experts;
