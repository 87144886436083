import React from "react";
import data from "../../Content/About/Vision.json";
import { useInView } from "react-intersection-observer";
import { Fade } from "react-reveal";
const Vision = () => {
  const [visible, setVisible] = React.useState(false);
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  React.useEffect(() => {
    if (inView) {
      setVisible(true);
    }
  }, [inView]);
  return (
    <div
      className="py-3 md:p-2   p-1 md:(px-5) md:px-3 max-w-7xl mx-auto"
      ref={ref}
    >
      <h1 className="md:text-4xl  text-2xl text-center md:mb-10 mb-5 font-bold uppercase tracking-wider text-[#C70000] font-Nunito">
        {data.heading}
      </h1>
      <div className="grid md:grid-cols-2 gap-10">
        {data.Vision.map((value) => (
          <Fade bottom delay={value.time} when={visible}>
            <div className="grid place-content-center place-items-center group rounded-lg space-y-3 md:p-8 p-3 border hover:bg-[#FFCF40] duration-300 ">
              <Fade bottom delay={value.time} when={visible}>
                <div className="rounded-full md:p-3 p-1 w-fit bg-[#DEEEEC]">
                  <img
                    src={value.image}
                    alt={value.image}
                    className="md:w-[100px] w-[50px] md:group-hover:scale-105  md:group-hover:duration-300"
                  />
                </div>
              </Fade>
              <Fade bottom delay={value.time} when={visible}>
                <h1 className="md:font-bold font-semibold md:text-xl text-lg uppercase">
                  {value.title}
                </h1>
              </Fade>
              <Fade bottom delay={value.time} when={visible}>
                <p className="text-center">{value.description}</p>
              </Fade>
            </div>
          </Fade>
        ))}
      </div>
    </div>
  );
};

export default Vision;
