import React from "react";
import Img from "../../Assets/career.jpg";
function Careers() {
  const data = [
    {
      desc: "Join our dynamic team at Suvan Remedies, where we are committed to improving lives by addressing unmet needs in the pharmaceutical industry and making a meaningful impact.",
    },
    {
      desc: "Be a part of our mission to Light Up Lives!",
    },
    {
      desc: "At Suvan Remedies, we provide a supportive environment where every individual is valued, encouraged to grow, and empowered to contribute to our collective success.",
    },
  ];
  return (
    <div className="md:flex  p-1 md:(px-5) md:px-3 max-w-7xl mx-auto gap-3 py-10">
      <div className="space-y-4">
        {data.map((d, i) => (
          <div key={i}>
            <p className="md:text-[16px] text-[12px] tracking-wide">{d.desc}</p>
          </div>
        ))}
        <p className="md:text-[16px] text-[12px] tracking-wide">
          Write a mail to{" "}
          <a
            href="mailto:info@suvanremedies.com"
            className="font-bold text-blue-500"
          >
            info@suvanremedies.com
          </a>{" "}
          and help us make a difference.
        </p>
      </div>

      <img
        src={Img}
        alt=""
        className="md:w-1/2 xl:h-full sm:h-[300px] w-full rounded-md"
      />
    </div>
  );
}

export default Careers;
