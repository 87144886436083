import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import img from "../../Assets/PatientInfo/Retaina/bg.png";
import Data from "../../Content/PatientInfo/Glacoma.json";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

function Glaucoma() {
  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div
      className="slider-container relative"
      id="4"
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <h2 className="p-2 text-black text-center md:text-3xl text-xl font-semibold uppercase tracking-wider font-Nunito">
        {Data.title}
      </h2>
      <Slider ref={sliderRef} {...settings}>
        {Data.list?.map((item, index) => (
          <div key={index} className="xl:px-10 sm:px-7 px-4 md:py-4 py-2">
            <div className="w-full md:flex md:gap-3">
              <div className="md:w-1/2 w-full space-y-10">
                {item.content?.map((contentItem, contentIndex) => (
                  <div key={contentIndex} className="space-y-3">
                    <h3 className="md:text-xl text-[10px] text-[#F26009] font-semibold md:pt-3 pt-1">
                      {contentItem.heading}
                    </h3>
                    <div className="space-y-2">
                      {Array.isArray(contentItem.li) ? (
                        contentItem.li.map((listItem, listIndex) => (
                          <div key={listIndex}>
                            <p className="text-black text-[10px] md:text-[14px] tracking-wide font-semibold">
                              {listItem.desc || listItem.Li}
                            </p>
                          </div>
                        ))
                      ) : (
                        <p className="text-black text-[10px] md:text-[14px] tracking-wide font-semibold">
                          {contentItem.li?.desc}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="md:w-1/2 md:space-y-3 grid gap-3">
                {item.content2 && Array.isArray(item.content2)
                  ? item.content2.map((content2Item, content2Index) => (
                      <div key={content2Index}>
                        {content2Item.img && Array.isArray(content2Item.img)
                          ? content2Item.img.map((imgItem, imgIndex) => (
                              <div key={imgIndex} className="w-full">
                                <img
                                  src={imgItem.img}
                                  alt=""
                                  className="w-full lg:h-[300px] h-[150px]"
                                />
                              </div>
                            ))
                          : null}

                        <div className="space-y-3">
                          {content2Item.heading && (
                            <h3 className="md:text-xl text-[10px] text-[#F26009] font-semibold md:pt-3 pt-1">
                              {content2Item.heading}
                            </h3>
                          )}
                          {content2Item.li && Array.isArray(content2Item.li) ? (
                            content2Item.li.map((liItem, liIndex) => (
                              <div key={liIndex}>
                                <p className="text-black text-[10px] md:text-[14px] tracking-wide font-semibold">
                                  {liItem.desc || liItem.Li}
                                </p>
                              </div>
                            ))
                          ) : (
                            <p className="text-black text-[10px] md:text-[14px] tracking-wide font-semibold">
                              {content2Item.desc}
                            </p>
                          )}

                          {/* Conditionally render consult text */}
                          {content2Item.consult && (
                            <p className="text-red-600    sm:text-[10px] text-[8px] lg:text-[14px] md:py-6 py-3 md:tracking-wide font-semibold">
                              {content2Item.consult}
                            </p>
                          )}
                        </div>
                      </div>
                    ))
                  : item.content2 && (
                      <div>
                        {item.content2.heading && (
                          <h3>{item.content2.heading}</h3>
                        )}
                        {item.content2.li && Array.isArray(item.content2.li) ? (
                          item.content2.li.map((liItem, liIndex) => (
                            <div key={liIndex}>
                              <p className="text-black text-[10px] md:text-[14px] tracking-wide font-semibold">
                                {liItem.desc || liItem.Li}
                              </p>
                            </div>
                          ))
                        ) : (
                          <p>{item.content2.desc}</p>
                        )}

                        {/* Conditionally render consult text */}
                        {item.content2.consult && (
                          <p className="text-red-600  xl:px-10 sm:px-7 px-5 md:text-end text-center  sm:text-[10px] text-[8px] lg:text-[14px] md:py-6 py-3 md:tracking-wide font-semibold">
                            {item.content2.consult}
                          </p>
                        )}
                      </div>
                    )}
              </div>
            </div>
          </div>
        ))}
      </Slider>

      <div className="absolute top-[50%] right-0">
        <button className="button" onClick={next}>
          <MdArrowForwardIos className="text-black/70 xl:text-5xl sm:text-3xl text-2xl rounded-full p-1" />
        </button>
      </div>

      <div className="absolute top-[50%] left-0">
        <button className="button" onClick={previous}>
          <MdArrowBackIos className="text-black/70 xl:text-5xl sm:text-3xl text-2xl rounded-full p-1" />
        </button>
      </div>
    </div>
  );
}

export default Glaucoma;
