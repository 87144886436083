import { useState } from "react";

import PropTypes from "prop-types";

import ZoomableImage from "./ZoomableImage";

import { TfiClose } from "react-icons/tfi";

const ThumbnailSlider = ({ data }) => {
  const [modalOpen, setModalOpen] = useState(false);

  console.log(data);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="">
      <div className=" ">
        <div className="">
          <img
            src={data}
            alt={data}
            className="  cursor-pointer   sm:w-[200px] w-[150px]"
            onClick={() => openModal()}
          />
        </div>
      </div>

      {modalOpen && (
        <div className="modal fixed z-[999] top-0 left-0 w-full h-full bg-white flex justify-center items-center overflow-hidden px-3">
          <div className="relative">
            <div className=" relative">
              <div className="mySlides  ">
                <ZoomableImage srcImage={data} alt={data} className="" />
              </div>
            </div>

            {/* arrows */}
            <div className=" gap-6 absolute -top-4 -right-12">
              <button
                className="bg-gray-400 w-10 h-10 rounded-full grid place-content-center shadow-xl cursor-pointer"
                onClick={closeModal}
              >
                <TfiClose className="text-md text-white font-bold" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ThumbnailSlider.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  goToSlide: PropTypes.func.isRequired,
};

export default ThumbnailSlider;
