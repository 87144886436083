import React from "react";
import PropTypes from "prop-types";
const CustomButton = (props) => {
  const { name } = props;
  return (
    <div className="">
      <button className="disabled:bg-blue-200 disabled:cursor-not-allowed buttons group inline-block w-fit px-9 h-[50px] rounded-[10px] border border-[#03045e] relative overflow-hidden transition-all duration-500 ease-in z-[1]">
        <span className="group-hover:text-white text-black text-[18px] transition-all duration-300 ease-in">
          {name}
        </span>
      </button>
    </div>
  );
};
CustomButton.propTypes = {
  name: PropTypes.string,
};
export default CustomButton;
