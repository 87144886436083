import React from "react";
import Experts from "../Components/Home/Experts";
import Export from "../Components/Home/Export";

import Brands from "../Components/Home/Brands";
import About from "../Components/Home/About";
import Exam from "../Components/Home/EyeDropSlider";
import EyeDropSlider from "../Components/Home/EyeDropSlider";

const HomePage = () => {
  return (
    <div>
      <EyeDropSlider />
      {/* <About /> */}
      <Export />
      <Experts />
      {/* <Brands /> */}
    </div>
  );
};

export default HomePage;
