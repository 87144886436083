import React, { useEffect, useState } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";

const CallButton = () => {
  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    window.addEventListener("scroll", scroll);
  }, []);

  const [scrolled, setScrolled] = useState(false);

  const scroll = () => {
    const scrollY = window.scrollY;
    if (scrollY > 250) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <div>
      {scrolled && (
        <a
          href="tel:+918977639771"
          className="bg-slate-800 border-2 text-white  md:w-12 w-10 md:h-12 h-10 rounded-full  fixed bottom-5 left-20 flex justify-center items-center z-[99]"
          aria-label="bottom to top "
        >
          <BsFillTelephoneFill />
        </a>
      )}
    </div>
  );
};

export default CallButton;
