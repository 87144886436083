import React, { useState } from "react";
import data from "../../Content/Home/Export.json";
import CustomButton from "../Common/CustomButton";
import { useInView } from "react-intersection-observer";
import { Fade } from "react-reveal";
const Export = () => {
  const [visible, setVisible] = React.useState(false);
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  React.useEffect(() => {
    if (inView) {
      setVisible(true);
    }
  }, [inView]);
  return (
    <div
      className="  py-3 md:p-2   p-1 md:(px-5) md:px-3 max-w-7xl mx-auto  "
      ref={ref}
    >
      <h1 className="md:text-4xl pt-10 text-2xl text-center md:mb-10 mb-5 font-bold uppercase tracking-wider text-[#C70000] font-Nunito">
        {data.title}
      </h1>
      <div className="grid md:grid-cols-4 grid-cols-2  gap-10">
        {data.export.map((value) => (
          <Fade bottom delay={value.time} when={visible}>
            <div className="grid place-items-center group cursor-pointer">
              <img
                src={value.image}
                alt={value.image}
                className="w-[200px] group-hover:scale-105 duration-500"
              />
              <p className="font-semibold md:text-[18px] text-[13px]">
                {value.title}
              </p>
            </div>
          </Fade>
        ))}
      </div>
      <div className="flex justify-center mt-10 sm:pb-10 pb-5">
        <div className="">
          {/* <button className="font-semibold uppercase md:text-md text-sm">
            {data.button}
          </button> */}
          <a href="/Suvan/Remedies/ProductsPage">
            <CustomButton name="View More" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Export;
