import React from "react";
import Vision from "../Components/About/Vision";
import AboutUs from "../Components/About/AboutUs";
import WhyChooseUs from "../Components/About/WhyChooseUs";

const AboutPages = () => {
  return (
    <div className="md:space-y-10 space-y-5">
      <AboutUs />
      <Vision />
      <WhyChooseUs />
    </div>
  );
};

export default AboutPages;
