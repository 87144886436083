import React from "react";
import Data from "../../Content/Products/Cataract.json";
import ThumbnailSlider from "./ThumbnailSlider";
function Cataract() {
  return (
    <div className="xl:px-0 px-3 max-w-7xl mx-auto   font-Nunito lg:pt-14 sm:pt-7 pt-4">
      <h2 className="text-center sm:text-3xl text-[20px] md:pb-10 py-3 tracking-wide">
        {Data.title}
      </h2>
      <div className="flex justify-center  gap-10">
        {Data?.list?.map((d, i) => (
          <div className="grid justify-center place-items-center ">
            <ThumbnailSlider data={d.img} />
            <div>
              <p className="tracking-wide md:text-start text-center xl:text-[12px] sm:text-[10px] text-[6px]">
                {d.desc}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Cataract;
