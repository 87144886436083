import React, { useEffect, useState } from "react";
import data from "../../Content/Gallery/Gallery.json";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { TfiClose } from "react-icons/tfi";
import { useInView } from "react-intersection-observer";
import { Fade } from "react-reveal";

const ImageGallery = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  const openModal = (index) => {
    setModalOpen(true);
    setSlideIndex(index);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const plusSlides = (n) => {
    const newIndex =
      (slideIndex + n + data?.gallery?.length) % data?.gallery?.length;
    setSlideIndex(newIndex);
  };

  const [visible, setVisible] = React.useState(false);
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  React.useEffect(() => {
    if (inView) {
      setVisible(true);
    }
  }, [inView]);

  return (
    <div
      className="p-2 px-3 md:(px-5) md:px-3 max-w-7xl mx-auto py-10 grid"
      ref={ref}
    >
      <h2 className="md:text-4xl text-2xl text-center md:m-10 m-5 font-bold uppercase tracking-wider text-[#C70000] font-Nunito">
        Gallery
      </h2>

      <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:gap-10 gap-20 ">
        {data?.gallery.map((image, index) => (
          <Fade fade delay={image.time} when={visible}>
            <div key={index} className="w-full md:space-y-5 space-y-3 ">
              <img
                src={image.image}
                alt="Img Not found"
                className="w-full h-full hover:shadow cursor-pointer  md:h-[300px]"
                onClick={() => openModal(index)}
              />

              <p className="text-[16px] font-semibold text-center">
                {image?.text}
              </p>
            </div>
          </Fade>
        ))}
      </div>

      {modalOpen && (
        <div className="modal fixed z-[999] top-0 left-0 w-full h-full bg-white flex justify-center items-center">
          {/* closeModal */}
          {/* <span
            className="close absolute top-0 right-0 text-white hover:text-black bg-black hover:bg-white md:text-6xl text-2xl cursor-pointer"
            onClick={closeModal}
          >
            &times;
          </span> */}

          <div className="modal-content relative p-5">
            <div className="mySlides">
              {/* <div className="text-white">
                {slideIndex + 1} / {data?.gallery?.length}
              </div> */}
              <img
                src={data.gallery[slideIndex].image}
                alt={data.gallery[slideIndex].name}
                className="w-[700px]"
              />
            </div>

            <div className="caption-container text-center text-white p-2">
              <p id="caption">{data.gallery[slideIndex].name}</p>
            </div>
          </div>

          {/* arrows */}
          <div className="flex items-center gap-6 absolute sm:bottom-2 bottom-20 right-54">
            <a
              className=" bg-[#FAF7F1] w-12 h-12 rounded-full grid place-content-center shadow-xl cursor-pointer"
              onClick={() => plusSlides(-1)}
            >
              <AiOutlineLeft />
            </a>
            <button
              className="close  bg-[#FAF7F1] w-16 h-16 rounded-full grid place-content-center shadow-xl cursor-pointer"
              onClick={closeModal}
            >
              <TfiClose className="text-xl" />
            </button>
            <a
              className=" bg-[#FAF7F1] w-12 h-12  rounded-full grid place-content-center shadow-xl cursor-pointer"
              onClick={() => plusSlides(1)}
            >
              <AiOutlineRight />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
