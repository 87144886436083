import React from "react";
import ContactUs from "../Components/Contact/ContactUs";

const ContactPage = () => {
  return (
    <div>
      <ContactUs />
      <div className="">
        <iframe
          className="w-full h-[50vh]"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.127194488477!2d78.50389681072353!3d17.453625200824565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9be436700025%3A0x525ac21e5a655452!2sSuvan%20Remedies%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1718950135974!5m2!1sen!2sin"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactPage;
