import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import img from "../../Assets/PatientInfo/Retaina/bg.png";
import Data from "../../Content/PatientInfo/Retina.json";
import { SlArrowLeft } from "react-icons/sl";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

export default function Retina() {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div
      className="slider-container relative "
      id="5"
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <h2 className="p-2 text-black text-center md:text-3xl text-xl font-semibold uppercase tracking-wider font-Nunito">
        {Data.title}
      </h2>
      <Slider
        ref={(slider) => {
          sliderRef = slider;
        }}
        {...settings}
      >
        {Data.list.map((d, i) => (
          <div key={i} className="xl:px-10 sm:px-7 px-5 h-full">
            <div>
              <div className="md:flex md:gap-6 h-full space-y-2 md:space-y-0 ">
                <div className="w-full">
                  {d.content.map((D, idx) => (
                    <div key={idx} className="md:space-y-3 space-y-1 w-full">
                      <h3 className="md:text-xl text-[10px] text-[#F26009] font-semibold md:pt-3 pt-1">
                        {D.heading}
                      </h3>
                      <div className="space-y-2">
                        <p className="text-black/80 text-[10px] md:text-[14px] tracking-wide font-semibold">
                          {D.desc}
                        </p>
                        <ol className="text-black/80 text-[10px] md:text-[14px] font-semibold">
                          <li>{D.li}</li>
                          <li>{D.li1}</li> <li>{D.li2}</li> <li>{D.li3}</li>
                          <li>{D.li4}</li>
                          <li>{D.li5}</li>
                        </ol>
                        <p className="text-black/80 text-[10px] md:text-[14px] tracking-wide font-semibold">
                          {D.desc1}
                        </p>

                        <p className="text-red-600  sm:text-[10px] text-[8px] lg:text-[14px] py-1 md:tracking-wide font-semibold">
                          {D.consult}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>

                <img
                  src={d.img}
                  alt=""
                  className="md:w-1/3 md:h-[430px] sm:h-[300px] h-[150px] w-full "
                />
              </div>
            </div>
            <div>
              <div className="md:pt-5 pt-3 ">
                <h3 className=" text-center text-black md:text-2xl text-xl font-semibold pb-3">
                  {d.title}
                </h3>
                <div className="grid grid-cols-5 md:gap-10 gap-2 justify-between items-center py-3 h-full w-full ">
                  {d?.content2?.map((c2, id) => (
                    <div className="h-full w-full">
                      <div
                        className="grid justify-center  gap-2 h-full place-items-center shadow-md  p-1 bg-white rounded-lg"
                        key={id}
                      >
                        <img
                          src={c2.img}
                          alt=""
                          className="xl:w-20 w-8 lg:h-20 h-8 "
                        />
                        <div className="grid">
                          <a
                            href={c2.link}
                            target="_blank"
                            alt="Image Not found"
                            className="text-blue-700 text-center font-semibold text-[8px] lg:text-[14px]"
                          >
                            {c2.text}
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      <div className="absolute top-[50%] right-0 ">
        <button className="button " onClick={next}>
          <MdArrowForwardIos className="text-black/70 xl:text-5xl sm:text-3xl text-2xl rounded-full p-1 " />
        </button>
      </div>

      <div className="absolute top-[50%] left-0 ">
        <button className="button" onClick={previous}>
          <MdArrowBackIos className="text-black/70 xl:text-5xl sm:text-3xl text-2xl rounded-full p-1 " />
        </button>
      </div>
    </div>
  );
}
