import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import CustomButton from "../Common/CustomButton";
import axios from "axios";
import { useForm } from "react-hook-form";

function ContactUs() {
  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    axios
      .post(
        "https://suvan-remedies-backend.onrender.com/contact-form",
        data,
        setLoading(true)
      )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          alert("Form Submitted Successfully");
          reset();
        }
      });
  };

  return (
    <div className="2xl:px-[500px] md:px-[200px] sm:px-[100px] px-[30px] md:py-8 py-5  space-y-5">
      <div className="space-y-2">
        <h1 className="uppercase text-center text-4xl font-Nunito text-blue-950">
          Contact Us
        </h1>
        <h2 className="text-center flex justify-center items-center gap-1 font-Nunito">
          <Link
            to="/"
            className="text-lg text-blue-950 flex justify-center items-center group hover:text-red-700"
          >
            Home
            <span>
              <IoIosArrowForward className="group-hover:text-red-700" />
            </span>
          </Link>
        </h2>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="md:py-6 py-3 md:space-y-6 space-y-3  md:px-6 px-3 rounded-2xl  border-2"
      >
        <div className="grid w-full space-y-1">
          <label className="w-full">
            Full Name<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            placeholder=" "
            {...register("name", {
              required: "Name is required",
              pattern: {
                value: /^[a-zA-Z ]+$/,
                message: "Please enter valid name",
              },
            })}
            onKeyUp={() => {
              trigger("name");
            }}
            className="border-2 text-black py-3 rounded-md w-full  focus:outline-none focus:border-blue-500 focus:ring-blue-500 "
          />
          {errors.name && (
            <small className="text-[#EB1414]" id="Name_error">
              {errors.name.message}
            </small>
          )}
        </div>
        <div className="grid w-full space-y-1">
          <label className="w-full">
            Email<span className="text-red-500">*</span>
          </label>
          <input
            type="emai"
            placeholder=" "
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Please enter valid email address",
              },
            })}
            onKeyUp={() => {
              trigger("email");
            }}
            className="border-2 text-black py-3 rounded-md w-full  focus:outline-none focus:border-blue-500 focus:ring-blue-500 "
          />
          {errors.email && (
            <small className="text-[#EB1414]" id="Email_error">
              {errors.email.message}
            </small>
          )}
        </div>
        <div className="grid w-full space-y-1 ">
          <label className="w-full">
            Phone Number<span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            {...register("number", {
              required: "number is required",
            })}
            onKeyUp={() => {
              trigger("number");
            }}
            className="border-2 text-black py-3 rounded-md w-full  focus:outline-none focus:border-blue-500 focus:ring-blue-500 "
          />
          {errors.number && (
            <small className="text-[#EB1414]" id="number">
              {errors.number.message}
            </small>
          )}
        </div>
        <div className="grid w-full space-y-1 ">
          <label className="w-full">
            Message<span className="text-red-500">*</span>
          </label>
          <textarea
            type="text"
            {...register("message", {})}
            className="border-2 text-black py-3 rounded-md w-full  focus:outline-none focus:border-blue-500 focus:ring-blue-500 "
          />
        </div>
        <div>
          <CustomButton
            disabled={isSubmitting || loading}
            name={isSubmitting || loading ? "Please wait..." : "Submit"}
          />
        </div>
      </form>
    </div>
  );
}

export default ContactUs;
