import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

function PatitentInfoDropdown() {
  return (
    <div class="relative inline-block text-left">
      <div class="group">
        <a
          href="/Suvan/Remedies/PatientInformation"
          type="button"
          class="inline-flex  font-medium text-black uppercase "
        >
          Patient Information
        </a>

        <div class="absolute left-0 w-40  origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition duration-300 z-50">
          <div class="py-1">
            <a
              href="#1"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Human Eye
            </a>
            <a
              href="#2"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Cornea
            </a>
            <a
              href="#3"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Glaucoma
            </a>

            <a
              href="#4"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Cataract
            </a>
            <a
              href="#5"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Retina
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PatitentInfoDropdown;
