import React from "react";
import HumanEye from "../Components/PatientInfo.js/HumanEye";
import DryEye from "../Components/PatientInfo.js/DryEye";
import Glaucoma from "../Components/PatientInfo.js/Glaucoma";
import Cataract from "../Components/PatientInfo.js/Cataract";
import Retina from "../Components/PatientInfo.js/Retina";

function PatientInfoPage() {
  return (
    <div className="space-y-10">
      <HumanEye />
      <DryEye />
      <Glaucoma />
      <Cataract />
      <Retina />
    </div>
  );
}

export default PatientInfoPage;
