import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import img from "../../Assets/PatientInfo/Retaina/bg.png";
import Data from "../../Content/PatientInfo/Cataract.json";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
function Cataract() {
  return (
    <div
      className="slider-container relative "
      id="4"
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <h2 className="p-2 text-black text-center md:text-3xl text-xl font-semibold uppercase tracking-wider font-Nunito">
        {Data?.title}
      </h2>

      <div className="md:flex">
        <div className="md:w-1/2">
          {Data?.list?.map((d, i) => (
            <div key={i} className="xl:px-10 md:px-7 px-6  md:py-4 py-2">
              <h3 className="md:text-xl text-[12px] text-[#F26009] font-semibold md:pt-3 pt-1 ">
                {d.title}
              </h3>

              {d?.list1 && (
                <>
                  {d?.list1?.map((value, id) => (
                    <div key={id} className="space-y-2">
                      <p className="text-black/80 text-[10px] md:text-[14px] tracking-wide font-semibold ">
                        {value.desc}
                      </p>

                      <img src={value?.img} alt="" />
                    </div>
                  ))}
                </>
              )}

              {d?.list2 && (
                <>
                  {d?.list2?.map((value, id) => (
                    <div key={id}>
                      <p className="text-black/80 text-[10px] md:text-[14px] tracking-wide font-semibold ">
                        {value.desc}
                      </p>
                    </div>
                  ))}
                </>
              )}
            </div>
          ))}
        </div>

        <div className="md:w-1/2">
          {Data?.list3?.map((d, i) => (
            <div
              key={i}
              className="xl:px-10 md:px-7 px-6  md:py-4 py-2 space-y-2"
            >
              <img src={d?.img} alt="" className="py-4" />
              <h3 className="md:text-xl text-[12px] text-[#F26009] font-semibold md:pt-3 pt-1 ">
                {d?.title}
              </h3>

              {d?.list1 && (
                <>
                  {d?.list1?.map((value, id) => (
                    <div key={id}>
                      <p className="text-black/80 text-[10px] md:text-[14px] tracking-wide font-semibold ">
                        {value?.desc}
                      </p>
                    </div>
                  ))}
                </>
              )}

              {d?.list2 && (
                <>
                  {d?.list2?.map((value, id) => (
                    <div key={id} className="space-y-1">
                      <p className="text-black/80 text-[10px] md:text-[14px] tracking-wide font-semibold ">
                        {value.desc}
                      </p>
                    </div>
                  ))}
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      <p className="text-red-600  xl:px-10 sm:px-7 px-5 md:text-end text-center  sm:text-[10px] text-[8px] lg:text-[14px] md:py-6 py-3 md:tracking-wide font-semibold">
        {Data?.consult}
      </p>
    </div>
  );
}

export default Cataract;
