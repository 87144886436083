import { useState, useEffect, useRef } from "react";
import { TbMenu2 } from "react-icons/tb";
import { motion, AnimatePresence } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import { useLocation } from "react-router-dom";
import Pdf from "../Files/SuvanPharmaVisialAid.pdf";
import { FaCloudDownloadAlt } from "react-icons/fa";
import PatitentInfoDropdown from "./PatitentInfoDropdown";
const Navbar = () => {
  const [opened, setOpened] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpened(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleNav = () => {
    setOpened(!opened);
  };

  const [navbar, setNavbar] = useState(false);
  const changBackground = () => {
    if (window.scrollY >= 150) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  const location = useLocation();
  const currentPath = typeof window === "undefined" ? "/" : location?.pathname;
  window.addEventListener("scroll", changBackground);
  return (
    <div
      className={` ${
        currentPath === "/"
          ? `${
              navbar
                ? "sticky top-0 z-50  bg-white w-full border-b   duration-500"
                : "  bg-white "
            }`
          : "sticky top-0 z-50  bg-white border-b "
      }`}
    >
      <div className="flex justify-between items-center    md:h-[10vh] p-2 px-3 md:(px-5) md:px-3 max-w-7xl mx-auto ">
        <div className="flex items-center ">
          <a href="/" className="flex gap-1 justify-center items-center ">
            <img
              src="/Assets/Logo.png"
              alt="Template logo"
              className="md:h-[50px] md:w-[50px] w-[40px] h-[40px]"
            />

            <img
              src="/Assets/Logo2.png"
              alt="Template logo"
              className="md:h-[50px] md:w-[80px] w-[70px]  h-[40px]"
            />
          </a>
        </div>
        <div className="hidden md:flex md:items-center space-x-6 text-dark">
          <NavLink to="/">
            <span className="   duration-500 tracking-wide lg:text-[16px] text-[10px]">
              Home
            </span>
          </NavLink>

          <NavLink to="/Suvan/Remedies/aboutPage">
            <span className="   duration-500 tracking-wide lg:text-[16px] text-[10px]">
              About Us
            </span>
          </NavLink>
          <NavLink to="/Suvan/Remedies/ProductsPage">
            <span className=" duration-500 tracking-wide lg:text-[16px] text-[10px]">
              Products
            </span>
          </NavLink>

          <NavLink to="/Suvan/Remedies/PatientInformation">
            <span className=" duration-500 tracking-wide lg:text-[16px] text-[10px]">
              Patient Information
            </span>
          </NavLink>

          <NavLink to="/Suvan/Remedies/gallery">
            <span className="  duration-500 tracking-wide lg:text-[16px] text-[10px]">
              Gallery{" "}
            </span>
          </NavLink>
          <NavLink to="/Suvan/Remedies/Careers">
            <span className="  duration-500 tracking-wide lg:text-[16px] text-[10px]">
              Career
            </span>
          </NavLink>
          <NavLink to="/Suvan/Remedies/ContactUs">
            <span className="  duration-500 tracking-wide lg:text-[16px] text-[10px]">
              Contact Us
            </span>
          </NavLink>
        </div>

        <div className="md:hidden md:ml-4">
          <button onClick={handleNav} className="text-light">
            {opened ? null : <TbMenu2 size={28} className="text-[36px] mt-2" />}
          </button>
        </div>
      </div>
      <AnimatePresence>
        {opened && (
          <motion.div
            ref={menuRef}
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{ stiffness: 200, damping: 20 }}
            className="fixed top-0 z-50 left-0 w-2/3 h-screen md:hidden block  bg-slate-800  text-lg  space-y-6"
          >
            <div className="flex text-dark bg-pinklight p-2 justify-end w-full font-Nunito">
              <button onClick={handleNav}>
                <RxCross2 size={28} className="text-white" />
              </button>
            </div>
            <div className="flex flex-col text-dark  px-2  space-y-4 ">
              <NavLink to="/" className="text-dark">
                <span className="text-white tracking-wider text-[32px]">
                  Home
                </span>
              </NavLink>
              <NavLink to="/Suvan/Remedies/aboutPage">
                <span className="text-white">About Us</span>
              </NavLink>
              <NavLink to="/Suvan/Remedies/ProductsPage">
                <span className="text-white">Products</span>
              </NavLink>

              <NavLink to="/Suvan/Remedies/PatientInformation">
                <span className="text-white">Patient Information </span>
              </NavLink>

              <NavLink to="/Suvan/Remedies/galleryPage">
                <span className="text-white">Gallery </span>
              </NavLink>
              <NavLink to="/Suvan/Remedies/Careers">
                <span className="text-white">Career </span>
              </NavLink>
              <NavLink to="/Suvan/Remedies/ContactUs">
                <span className="text-white">Contact Us </span>
              </NavLink>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const NavLink = ({ to, children }) => {
  const location = useLocation();
  const currentPath = typeof window === "undefined" ? "/" : location?.pathname;

  return (
    <a href={to}>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.2 }}
        className={`${
          currentPath === to && " !text-[#C70000]   rounded-md"
        }  hover:!text-secondary font-semibold text-light uppercase `}
      >
        {children}
      </motion.p>
    </a>
  );
};

export default Navbar;
