import React from "react";
import OurProducts from "../Components/Products/OurProducts";
import DryEye from "../Components/Products/DryEye";
import Cataract from "../Components/Products/Cataract";
import AntiOxidantsMultiVitamins from "../Components/Products/AntiOxidantsMultiVitamins";
import Img from "../Assets/Products/whogmp1.jpg";
function ProductsPage() {
  return (
    <div className="sm:py-5 py-3">
      {/* <OurProducts /> */}

      <div className="flex justify-center items-center  md:pb-10 pb-5">
        <img src={Img} alt="" className="w-32 h-32 md:w-44 md:h-44" />
      </div>
      <DryEye />
      <Cataract />

      <AntiOxidantsMultiVitamins />
    </div>
  );
}

export default ProductsPage;
