import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { LuPhone } from "react-icons/lu";
import { MdOutlineEmail } from "react-icons/md";

const Footer = () => {
  return (
    <div className="bg-white p-2 px-3 md:(px-5) md:px-3 max-w-7xl mx-auto">
      <div className="md:p-10 bg-light    md:px-4  mx-auto mt-5 gap-8  font-semibold    p-4 space-y-3">
        <div className="sm:flex w-full justify-between md:space-y-0 space-y-6">
          <div className="w-full space-y-3 ">
            <div className="flex items-center  ">
              <a href="/" className="flex gap-1 justify-center items-center ">
                <img
                  src="/Assets/Logo.png"
                  alt="Template logo"
                  className="md:h-[50px] md:w-[50px] w-[40px] h-[40px]"
                />

                <img
                  src="/Assets/Logo2.png"
                  alt="Template logo"
                  className="md:h-[50px] md:w-[80px] w-[70px]  h-[40px]"
                />
              </a>
            </div>
          </div>
          <div className="space-y-3 tracking-wider w-full">
            <h4 className=" font-bold text-[#185041] ">QUICK LINKS</h4>
            <ul className="spaxe-y-1">
              <li>
                <a href="/Suvan/Remedies/aboutPage" className="text-black/70">
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/Suvan/Remedies/ProductsPage"
                  className="text-black/70"
                >
                  Products
                </a>
              </li>
              <li>
                <a href="/Suvan/Remedies/ContactUs" className="text-black/70">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="/Suvan/Remedies/gallery" className="text-black/70">
                  Gallery
                </a>
              </li>
              <li>
                <a
                  href="/Suvan/Remedies/PatientInformation"
                  className="text-black/70"
                >
                  Patient Information
                </a>
              </li>
            </ul>
          </div>

          <div className="w-full space-y-3 tracking-wider">
            <div className="space-y-3">
              <h4 className="font-bold text-[#185041]"> CONTACT US</h4>
              <ul className="space-y-1 ">
                <a
                  href="tel:+918977639771"
                  className="md:text-[14px] text-[10px] flex items-center gap-2"
                >
                  {" "}
                  <span>
                    <LuPhone className="md:text-xl" />
                  </span>
                  <span className="text-black/70">+91 8977639771</span>
                </a>
                <a
                  href="mailto:info@suvanremedies.com"
                  className="md:text-[14px] text-[10px] flex items-center gap-2"
                >
                  <span>
                    <MdOutlineEmail className="md:text-xl" />
                  </span>
                  <span className="text-black/70">info@suvanremedies.com</span>
                </a>
              </ul>
            </div>

            <div className="space-y-3 tracking-wider">
              <h4 className="text-[#185041] font-bold ">Follow Us</h4>

              <div className="flex gap-3 text-2xl">
                <a
                  className="rounded-full shadow-lg group "
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebook className="sm:text-4xl text-3xl sm:p-1.5 p-1 group-hover:scale-105 duration-300" />
                </a>

                <a
                  className="rounded-full shadow-lg group "
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaXTwitter className="sm:text-4xl text-3xl sm:p-1.5 p-1  group-hover:scale-105 duration-300" />
                </a>
                <a
                  className="rounded-full shadow-lg group"
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram className="sm:text-4xl text-3xl sm:p-1.5 p-1 group-hover:scale-105 duration-300" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" sm:text-center py-4 tracking-wider">
        <div className="w-full flex justify-end">
          <div className="w-[80%] sm:text-md text-sm  sm:w-full">
            © {new Date().getFullYear()}
            <a
              href="/"
              target="_blank"
              className="text-dark font-semibold  text-[14px]"
            >
              {" "}
              Suvan remedies{" "}
            </a>
            All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
