import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import img from "../../Assets/PatientInfo/Retaina/bg.png";
import Data from "../../Content/PatientInfo/DryEye.json";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
function DryEye() {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div
      className="slider-container relative "
      id="4"
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <h2 className="p-2 text-black text-center md:text-3xl text-xl font-semibold uppercase tracking-wider font-Nunito">
        {Data.title}
      </h2>
      <Slider
        ref={(slider) => {
          sliderRef = slider;
        }}
        {...settings}
      >
        {Data?.list?.map((d, i) => (
          <div key={i} className="xl:px-10 sm:px-7 px-5  md:py-4 py-2">
            <div>
              <div className="md:flex md:gap-6 h-full space-y-2 md:space-y-0 ">
                <div className="md:w-1/2 w-full">
                  {d?.content?.map((D, idx) => (
                    <div key={idx} className="md:space-y-3 space-y-1 w-full">
                      <h3 className="md:text-xl text-[12px] text-[#F26009] font-semibold md:pt-3 pt-1">
                        {D.heading}
                      </h3>
                      <div className="space-y-2">
                        <p className="text-black/80 text-[10px] md:text-[14px] tracking-wide font-semibold">
                          {D.desc}
                        </p>
                        <p className="text-black/80 text-[10px] md:text-[14px] tracking-wide font-semibold">
                          {D.desc1}
                        </p>
                        <p className="text-black/80 text-[10px] md:text-[14px] tracking-wide font-semibold">
                          {D.desc2}
                        </p>
                        <p className="text-black/80 text-[10px] md:text-[14px] tracking-wide font-semibold">
                          {D.desc3}
                        </p>

                        <ol className="text-black/80 text-[10px] md:text-[14px] space-y-1 font-semibold">
                          <li>{D.li}</li>
                          <li className="text-red-400">{D.li1}</li>{" "}
                          <li>{D.li2}</li> <li>{D.li3}</li>
                          <li>{D.li4}</li>
                          <li>{D.li5}</li>
                          <li>{D.li6}</li>
                          <li>{D.li7}</li>
                        </ol>
                        <p className="text-black/80 text-[10px] md:text-[14px] tracking-wide font-semibold">
                          {D.desc1}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="md:w-1/2  w-full md:py-0 py-2 md:grid flex gap-2">
                  {d?.img ? (
                    <img
                      src={d?.img}
                      alt=""
                      className="w-full md:h-[200px] sm:h-[300px] h-[150px] "
                    />
                  ) : (
                    ""
                  )}

                  <div className="">
                    {d?.list2?.map((d2, i2) => (
                      <div key={i2} className="w-full md:space-y-3 space-y-1">
                        <h3 className="md:text-xl text-[12px] text-[#F26009] font-semibold md:pt-3 pt-1">
                          {d2.heading}
                        </h3>

                        <ol className="text-black/80 text-[10px] sm:text-[14px] font-semibold">
                          <li>{d2.li}</li>
                          <li>{d2.li1}</li> <li>{d2.li2}</li> <li>{d2.li3}</li>
                          <li>{d2.li4}</li>
                          <li>{d2.li5}</li>
                          <li>{d2.li6}</li>
                          <li>{d2.li7}</li>
                        </ol>

                        <p className="text-black/80 text-[10px] md:text-[14px] tracking-wide font-semibold">
                          {d2.desc}
                        </p>
                        <p className="text-black/80 text-[10px] md:text-[14px] tracking-wide font-semibold">
                          {d2.desc1}
                        </p>
                        <p className="text-black/80 text-[10px] md:text-[14px] tracking-wide font-semibold">
                          {d2.desc2}
                        </p>

                        <p className="text-red-600  sm:text-[10px] text-[8px] lg:text-[14px] py-1 md:tracking-wide">
                          {d2?.consult}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      <div className="absolute top-[50%] right-0 ">
        <button className="button " onClick={next}>
          <MdArrowForwardIos className="text-black/70 xl:text-5xl sm:text-3xl text-2xl rounded-full p-1 " />
        </button>
      </div>

      <div className="absolute top-[50%] left-0 ">
        <button className="button" onClick={previous}>
          <MdArrowBackIos className="text-black/70 xl:text-5xl sm:text-3xl text-2xl rounded-full p-1 " />
        </button>
      </div>
    </div>
  );
}

export default DryEye;




// import React, { useRef } from "react";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
// import img from "../../Assets/PatientInfo/Retaina/bg.png";
// import Data from "../../Content/PatientInfo/DryEye.json";
// import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
// import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
// function DryEye() {
//   let sliderRef = useRef(null);
//   const next = () => {
//     sliderRef.slickNext();
//   };
//   const previous = () => {
//     sliderRef.slickPrev();
//   };
//   const settings = {
//     dots: false,
//     infinite: true,
//     fade: true,
//     arrows: false,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//   };
//   return (
//     <div
//       className="slider-container relative "
//       id="4"
//       style={{
//         backgroundImage: `url(${img})`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         backgroundRepeat: "no-repeat",
//       }}
//     >
//       <h2 className="p-2 text-black text-center md:text-3xl text-xl font-semibold uppercase tracking-wider font-Nunito">
//         {Data.title}
//       </h2>
//       <Slider
//         ref={(slider) => {
//           sliderRef = slider;
//         }}
//         {...settings}
//       >
//         {Data?.list1?.map((d, i) => (
//           <div>
//             <h2>{d.title}</h2>

//             {d?.li?.map((d2, i2) => (
//               <div>
//                 <p>{d2?.li}</p>
//               </div>
//             ))}
//           </div>
//         ))}
//       </Slider>

//       <div className="absolute top-[50%] right-0 ">
//         <button className="button " onClick={next}>
//           <MdArrowForwardIos className="text-black/70 xl:text-5xl sm:text-3xl text-2xl rounded-full p-1 " />
//         </button>
//       </div>

//       <div className="absolute top-[50%] left-0 ">
//         <button className="button" onClick={previous}>
//           <MdArrowBackIos className="text-black/70 xl:text-5xl sm:text-3xl text-2xl rounded-full p-1 " />
//         </button>
//       </div>
//     </div>
//   );
// }

// export default DryEye;
