import React from "react";
import data from "../../Content/About/WhyChooseUs.json";
import { useInView } from "react-intersection-observer";
import { Fade } from "react-reveal";
const WhyChooseUs = () => {
  const [visible, setVisible] = React.useState(false);
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  React.useEffect(() => {
    if (inView) {
      setVisible(true);
    }
  }, [inView]);
  return (
    <div className="bg-[#234182] md:py-10 py-4">
      <div className="p-1 md:(px-5) md:px-3 max-w-7xl mx-auto " ref={ref}>
        <Fade fade when={visible}>
          <h1 className="md:text-4xl text-2xl text-center md:mb-10 mb-5 font-bold uppercase tracking-wider text-white font-Nunito">
            {data.heading}
          </h1>
        </Fade>

        <div className="grid md:grid-cols-2 place-content-center place-items-center md:gap-10 gap-5 text-white">
          {data.choose.map((value) => (
            <Fade bottom delay={value.time} when={visible}>
              <div className="grid h-full w-full place-content-center place-items-center md:space-y-3 space-y-1 border border-white rounded-lg md:p-5 p-3">
                <Fade bottom delay={value.time} when={visible}>
                  <div className="bg-white rounded-full p-3">
                    <img
                      src={value.image}
                      alt={value.img}
                      className="md:w-[40px] w-[30px]"
                    />
                  </div>
                </Fade>
                <Fade bottom delay={value.time} when={visible}>
                  <h1 className="font-bold md:text-xl text-lg">
                    {value.title}
                  </h1>
                </Fade>
                <Fade bottom delay={value.time} when={visible}>
                  <p className="text-center">{value.description}</p>
                </Fade>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
