import React from "react";
import data from "../../Content/About/AboutUs.json";
import { useInView } from "react-intersection-observer";
import { Fade } from "react-reveal";
const AboutUs = () => {
  const [visible, setVisible] = React.useState(false);
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  React.useEffect(() => {
    if (inView) {
      setVisible(true);
    }
  }, [inView]);
  return (
    <div className="p-1 md:(px-5) md:px-3 max-w-7xl mx-auto" ref={ref}>
      <h1 className="md:text-4xl text-2xl text-center md:mb-10 mb-5 font-bold uppercase tracking-wider text-[#C70000] font-Nunito">
        {data.title}
      </h1>
      <div className="md:flex gap-10 md:space-y-0 space-y-10">
        <Fade fade delay={50} when={visible}>
          <div className="md:w-1/2">
            <img
              src={data.image}
              alt={data.image}
              className="w-full md:h-[400px] rounded-lg"
            />
          </div>
        </Fade>
        <div className="md:w-1/2 self-end space-y-4">
          <Fade fade delay={50} when={visible}>
            <h1 className="md:text-3xl text-xl font-semibold text-[#185041]">
              {data.heading}
            </h1>
          </Fade>
          <Fade fade delay={75} when={visible}>
            <p className="md:leading-7">{data.description}</p>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
